const ScreenLoading = () => {
  return (
    <div id='logo-loader' className='text-center logo-loader'>
      <img
        className='img-fluid logo-loader-img'
        alt='logo loading'
        src={require('../../assets/images/wellapartner-icon.svg').default}
      />
    </div>
  );
};
export default ScreenLoading;
